import React from 'react'
import { graphql } from 'gatsby'
import {
  Block,
  Breadcrumbs,
  Layout,
  Hero,
  Container,
  PostMeta
} from '@components'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import BlockWrapper from '@pageBuilder/wrapper'

const Post = ({ data, pageContext }) => {
  const {
    moduleArea,
    title,
    path,
    seoMetaTags,
    image,
    meta,
    imageMobile,
    categories
  } = data.post

  return (
    <Layout>
      <HelmetDatoCms defer={false} seo={seoMetaTags} />
      <Hero image={image} mobileImage={imageMobile} />
      {pageContext?.breadcrumbs && (
        <Breadcrumbs breadcrumbs={pageContext?.breadcrumbs} slim />
      )}
      <Block paddingTop={'single'}>
        <Container center>
          <h1>{title}</h1>
          <PostMeta categories={categories} date={meta.firstPublishedAt} />
        </Container>
      </Block>
      <BlockWrapper blocks={moduleArea} />
    </Layout>
  )
}

export const query = graphql`
  query postById($id: String!, $dateLocale: String!) {
    post: datoCmsPost(id: { eq: $id }) {
      id
      slug
      path
      title
      image {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { h: "550", fit: "crop", w: "1600", q: 60 }
        )
        alt
      }
      imageMobile: image {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { h: "600", fit: "crop", w: "750", q: 60 }
        )
        alt
      }
      meta {
        firstPublishedAt(formatString: "DD MMMM YYYY", locale: $dateLocale)
      }
      categories {
        id
        title
        slug
      }
      moduleArea {
        ... on DatoCmsAccordion {
          ...Accordion
        }
        ... on DatoCmsCardGrid {
          ...CardGrid
        }
        ... on DatoCmsContent {
          ...Content
        }
        ... on DatoCmsImage {
          ...Image
        }
        ... on DatoCmsVideo {
          ...Video
        }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }

    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
export default Post
